/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


function initBase($scope, $http, $timeout, $sce, $compile, $window, $location, $postService, $alertService) {
    
    $scope.mobilecheck = function () {
        var check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
                check = true
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    $scope.signalRange = [ 10, 8, 6, 4, 2 , 0];

	$scope.signalStrength = function(minutes){
		var range =  $scope.signalRange;
		var strength = -1;
		for(var i=0;i<range.length;i++){
			if(minutes>=range[i]){
				strength = i;
				break;
			}
		}
		return strength;
	};
	
	$scope.getStrengthFromPoint = function(point){
		var lastUpdate = moment.utc(point.lastUpdate, "YYYY-MM-DD HH:mm:ss").local();
		var now = moment();
		var diffInMinutes = now.diff(lastUpdate, 'minute');
		return $scope.signalStrength(diffInMinutes);				
	};
	
	$scope.isOldPosition = function(point){
		var deviceTime = moment.utc(point.deviceTime, "YYYY-MM-DD HH:mm:ss").local();
		var now = moment();
		var diffInMinutes = now.diff(deviceTime, 'minute');
		var strength =  $scope.signalStrength(diffInMinutes);
		return strength === 0;
	};


    $scope.callMobile = function (number) {
        if ($scope.mobilecheck()) {
            $window.open('tel:+91' + number);
        } else {
            alert('Please call on +91-' + number + ' to contact driver.');
        }

    };
    
    $scope.viewByTrackerId = function(trackerId){
    	if(typeof trackerId==='undefined' || trackerId ===null){
    		$alertService('Please specify tracker id');
    		return;
    	}
    	$scope.goto('/track/'+trackerId);
    };

 

    $scope.processPost = function (url, postParam, callback, config) {
        if (typeof config === "undefined" || config === null) {
            config = {};
        }
        config.headers = {
            'Authorization': 'bearer ' + $scope.token
        };
        $http.post(url, postParam, config).then(function (response) {
            if (typeof callback === "function") {
                callback(response);
            }
        }, function (data) { // the data parameter here is a jqXHR instance
            console.log('server errors', data);
        });


    };

    $scope.setProto = function(obj, cls){
    	Object.setPrototypeOf(obj, cls.prototype);
    };
    
    $scope.updatePagination = function (pagination) {
        var start = pagination.page;
        var max = Math.min(pagination.last_page, start + 4);
        var diff = max - start;
        if (diff < 4) {
            start = Math.max(max - 4, 1);
        }
        pagination.pages = [];
        for (var i = start; i <= max; i++) {
            pagination.pages.push(i);
        }
    };

    var directionsService = null;
    $scope.initMapPointer = function (div, zoomIndex, center) {    	
        if (null === directionsService) {
            directionsService = new google.maps.DirectionsService;
        }
        if (typeof center === "undefined") {
            center = {lat: 28.66, lng: 77.13};
        }
        var zoom = 6;
        if(typeof zoomIndex !=='undefined' & zoomIndex !==null){
        	zoom = zoomIndex;
        }      
        var directionsDisplay = new google.maps.DirectionsRenderer;
        var map = new google.maps.Map(div, {
            disableDefaultUI: true,
            scrollwheel: false,
            zoomControl: true,
            zoom: zoom,
            center: center,
            fullscreenControl: true,
        });
        directionsDisplay.setMap(map);
        return [map, directionsDisplay, directionsService];
    };

    $scope.getTimeFromMinute = function (min) {
        var t = [];
        min = Math.floor(min);
        if (min > 60) {
            var hour = Math.floor(min / 60);
            min -= hour * 60;
            if (hour > 24) {
                var days = Math.floor(hour / 24);
                hour -= days * 24;
                t.push(days + ' day');
            }
            if (hour > 0) {
                t.push(hour + ' hour');
            }
        }
        if (min > 0) {
            t.push(min + ' min');
        }
        if (t.length === 0) {
            t.push(' 0 min');
        }
        return t.join(" ");
    };

    $scope.getTimeFromSeconds = function (seconds) {
        var min = Math.floor(seconds / 60);
        return $scope.getTimeFromMinute(min);
    };


    $scope.toUTCFormat = function (time, format, toFormat) {
        var time = moment(time, format);
        if (time.isValid()) {
            return time.utc().format(toFormat);
        } else {
            return null;
        }
    };

    $scope.toLocal = function (time, format, toFormat) {
        format = typeof format !== 'undefined' ? format : 'YYYY-MM-DD HH:mm:ss';
        toFormat = typeof toFormat !== 'undefined' ? toFormat : 'YYYY-MM-DD HH:mm:ss';
        var time = moment.utc(time, format);
        if (time.isValid()) {
            return time.local().format(toFormat);
        } else {
            return null;
        }
    };

    $scope.getGetParam = function (param) {
        return {
            headers: {
                'Authorization': 'bearer ' + $scope.token
            },
            params: param
        };
    };
    
    $scope.goto = function (url, next) {
    	if(typeof next !=='undefined' && next){
    		$window.open(url, "_blank")	
    	}else{
    		$window.location = url;
    	}
        
    };
    
    $scope.isValidString = function (string) {
        return !(typeof string === 'undefined' || string === null || string.length === 0)
    };

    $scope.preference = {}
    $scope.getUserPreference = function (callback) {
        var url = '/api/user/get_user_preference';
        $postService(url, {}, function (response) {
            var preference = response.data.preference;
            for (var i = 0; i < preference.length; i++) {
                $scope.preference[preference[i].key] = preference[i].value;
            }
            if (typeof callback === 'function') {
                callback();
            }
        });
    };


    $scope.saveUserPreference = function (key, value) {
        var url = '/api/user/save_user_preference';
        var data = {
            key: key,
            value: value
        };
        $postService(url, data, function (response) {
            $alertService(response.data.message, response.data.messagetype);
        });
    };
    
    $scope.getTruckStatusBase = function (truck) {
    	var strength = $scope.getStrengthFromPoint(truck);
		if (strength == 0) {
			return "Unknown";
		}

		if ($scope.isOldPosition(truck)) {
			return "Stopping";
		}

		if (truck.speed > 0) {
			return "Moving";
		}

		var attributes = truck.attributes;
		if (truck.attributes === null) {
			return "Stopping";
		}
		if ((attributes.indexOf('"io239":1') !== -1) || (attributes.indexOf('"ignition":1') !== -1) || (attributes.indexOf('"ignition":true') !== -1)) {
			return "Idling";
		}
		return "Stopping";
    };
    $scope.getGeofenceParam = function (data) {
        var gdata = {}
        if (data.startsWith("CIRCLE")) {
            gdata.type = "CIRCLE";
            var len = data.length;
            var start = data.indexOf("(") + 1;
            var end = len - start - 1;
            var attr = data.substr(start, end);
            var sp = attr.split(",");
            var radius = parseFloat(sp[1]);
            var center = sp[0].split(" ");
            gdata.center = {
                lat: parseFloat(center[0]),
                lng: parseFloat(center[1])
            };
            gdata.radius = radius;
        } else if (data.startsWith("LINESTRING")) {
            gdata.type = "LINE";
        }
        return gdata;
    };
    
    $scope.otoa = function (obj) {
        return Object.keys(obj).map(function (key) {
            return obj[key];
        });
    };

    $scope.atoo = function (arr, key) {
        return arr.reduce(function (result, data) {
            result[data[key]] = data;
            return result;
        }, {});
    };
    
    $scope.sortObj = function(obj, id){
    	var list = [];
    	for (var key in obj) {
			var value = obj[key];
			var index = value.id;
			list.push({
				index : index,
				key : key,
				value: value
			});
		}
		list.sort(function(a, b) {
			return (a.index - b.index);
		});
		return list;
    };

    $scope.handleGroupsLoaded = function(data, status) {
        console.log(data);
    };
}
