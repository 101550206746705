/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
'use strict';
var app = angular.module("app", []);
app.version = 1;
app.config(function ($interpolateProvider) {
    $interpolateProvider.startSymbol('[[');
    $interpolateProvider.endSymbol(']]');
});
app.controller("indexController", function ($scope, $http, $q, $sce, $timeout, $compile, $window, $location, $userService, $postService, $loadService, $alertService, $getService) {
    initBase($scope, $http, $timeout, $sce, $compile, $window, $location, $postService, $alertService);
    initLogin($scope, $http, $window, $postService, $alertService);
});
