/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


function initLogin($scope, $http, $window, $postService, $alertService) {

	$scope.isTrackerVisible = false;
    $scope.isLoginVisible = false;
   

    $scope.showTracker = function () {
        $scope.isLoginVisible = false;
        $scope.isTrackerVisible = !$scope.isTrackerVisible;
    };
    
	 $scope.showLogin = function () {
	        $scope.isTrackerVisible = false;
	        $scope.isLoginVisible = !$scope.isLoginVisible;
	 };
	    
    $scope.signin_param = {
        email: null,
        password: null,
    };


    $scope.doSignIn = function () {
        var param = $scope.signin_param;
        if (param.email === null || param.password === null) {
            $alertService('Please provide valid credentials.', 1);
            return;
        } else {
            var pattern = new RegExp(/[~`!#$%\^&*+=\[\]\\';,/{}|\\":<>\?]/); //unacceptable chars
            if (pattern.test(param.email)) {
                $alertService('Please only use standard alphanumerics for username.', 1);
                return;
            }

            var pattern = new RegExp(/[~`!#$%\^&*+=\[\]\\';,/{}|\\":<>\?]/); //unacceptable chars
            if (pattern.test(param.password)) {
                $alertService('Please use valid characters for password.', 1);
                return;
            }

            var url = '/login';
            $postService(url, param, function (response) {
                if (response.data.status) {
                    localStorage.setItem('token', response.data.token);
                    $alertService(response.data.message, response.data.messagetype);
                    var location = response.data.location;
                    if (typeof location !== 'undefined' && location !== null) {
                        $scope.goto(location);
                    } else {
                        $window.location.reload();
                    }
                } else {
                    $alertService(response.data.message, response.data.messagetype);
                }
            });
        }
    };
}